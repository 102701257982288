import axios from 'axios';
import Utils from '../utils/utils';

const Router = global.$router;

const BASE_URL = Utils.getBaseURL();
console.info(`Host: ${BASE_URL}`);

const HttpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 60000, // indicates, 5000ms ie. 5 second
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const requestAuthInterceptor = (config) => {
  const client = Utils.getClient();
  if (client) {
    const requestConfig = { ...config };
    requestConfig.headers['x-sub-client'] = client;
    return requestConfig;
  }
  return config;
};

const responseInterceptor = (res) => res.data;

const responseErrorInterceptor = (error) => {
  if (error) {
    if (error.response) console.error(error.response.status);
    console.error(error.message);
  }

  console.error(error.response.status, error.message);
  if (error.message && error.message.includes('Network Error')) {
    // TODO Handle network error
  } else {
    switch (error.response.status) {
      case 400:
        break;

      case 401: {
        const currentPath = Router.history.current.path;
        if (!currentPath || !currentPath.includes('login')) {
          Router.push({ name: 'HomePage' });
        }
        break;
      }
      case 403: {
        Router.push({ name: 'AuthErrorPage' });
        break;
      }
      case 412:
        console.error(error.response.status, error.message);
        Router.push({ name: 'CustomErrorPage' });
        break;

      default:
        console.error(error.response.status, error.message);
    }
  }
  return Promise.reject(error);
};

HttpClient.interceptors.request.use(requestAuthInterceptor);
HttpClient.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

HttpClient.getBaseURL = () => HttpClient.defaults.baseURL;

export default HttpClient;
