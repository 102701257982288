import HttpClient from '@/api/http-client';

const getAuthParams = () => HttpClient.get('/auth');

const logIn = (body) => {
  const path = '/auth/login';
  return HttpClient.post(path, body);
};

const logOut = () => {
  const path = '/auth/logout';
  return HttpClient.post(path);
};

const getMe = () => {
  const path = '/auth/me';
  return HttpClient.get(path);
};

export default {
  getMe,
  getAuthParams,
  logIn,
  logOut,
};
